import React from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { apiLogin, apiMe } from "../api/axios";
import { ILoginInput, ILoginOutput } from "../api/type";
import { FormButton } from "../component/form-button";
import { FormError } from "../component/form-error";
import { LOCALSTORAGE_TOKEN, UserRole, UserTestState } from "../constant";
export const TesterLogin = () => {
  const naviage = useNavigate();
  const queryClient = useQueryClient();
  useQuery("me", apiMe, {
    onSuccess: (data) => {
      if (data) {
        switch (data.testState) {
          case UserTestState.Pending:
            naviage(`/orientation?normid=${data.group[0].normId}`);
            break;

          case UserTestState.InProgress:
            naviage(`/test`);
            break;

          case UserTestState.Done:
            naviage(`/preview`);
            break;

          default:
            break;
        }
      }
    },
  });
  const {
    register,
    getValues,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<ILoginInput>({ mode: "onChange" });

  const loginMutation = useMutation(apiLogin, {
    onSuccess: (data: ILoginOutput) => {
      if (data.ok && data.token) {
        localStorage.setItem(LOCALSTORAGE_TOKEN, data.token);
        queryClient.invalidateQueries("me");
      }
    },
  });

  const onSubmit = () => {
    if (!loginMutation.isLoading) {
      const { name, password } = getValues();
      loginMutation.mutate({ name, password, role: UserRole.Tester });
    }
  };

  return (
    <div className="h-screen flex items-center justify-center min-w-min select-none">
      <Helmet>
        <title>지원자 로그인</title>
      </Helmet>
      <div className="w-full max-w-lg">
        <div className="w-full text-center text-7xl font-bold text-gray-600">
          DISE
        </div>
        <div className="w-full text-center text-2xl text-gray-600 mt-10 mb-10">
          <span className="font-semibold ">D</span>OOSAN{" "}
          <span className="font-semibold ">I</span>ntegrated{" "}
          <span className="font-semibold ">S</span>imulation{" "}
          <span className="font-semibold ">E</span>xercise
        </div>
        <div
          style={{ backgroundColor: "rgb(0, 24, 168)" }}
          className="  w-full max-w-lg py-10 px-16 text-center"
        >
          <h3 className=" font-medium text-2xl text-white ">지원자 로그인</h3>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid gap-3 mt-5 px-5"
          >
            <input
              {...register("name", { required: "이름은 필수 입력값 입니다." })}
              placeholder="name"
              required
              className="text-center bg-gray-100 shadow-inner focus:ring-2 focus:ring-gray-400 focus: outline-none my-1  py-3 px-3  "
            />
            {errors.name?.message && (
              <FormError errorMessage={errors.name?.message} />
            )}
            <input
              {...register("password", {
                required: "인증키는 필수 입력값 입니다.",
              })}
              type="password"
              placeholder="인증키"
              required
              className="text-center bg-gray-100 shadow-inner focus:ring-2 focus:ring-gray-400 focus: outline-none my-1  py-3 px-3  "
            />
            {errors.password?.message && (
              <FormError errorMessage={errors.password?.message} />
            )}
            <FormButton
              canClick={isValid}
              loading={loginMutation.isLoading}
              actionText={"접속"}
            />
            {loginMutation.data?.error && (
              <FormError errorMessage={loginMutation.data.error} />
            )}
          </form>
        </div>
        <div className=" flex justify-center mt-28">
          <svg
            id="레이어_1"
            data-name="레이어 1"
            xmlns="http://www.w3.org/2000/svg"
            height="10mm"
            viewBox="0 0 621.09 85.33"
          >
            <title>doosan_ci</title>
            <path
              d="M79.62,3.57H29.29L1,83.43H51.34c40.07,0,48.86-19,56.27-39.94s12.09-39.92-28-39.92m-7.8,39.92C64.17,65.09,61.3,68.44,47.88,68.44H41L58.65,18.55h6.9c13.42,0,13.91,3.36,6.27,24.94M174.84,2c-35.43,0-51.66,11.64-62.24,41.49S110,85,145.44,85s51.66-11.63,62.24-41.51S210.26,2,174.84,2m-3,41.49c-7.64,21.6-10.82,27.41-21.45,27.41s-9.68-5.81-2-27.41,10.83-27.4,21.45-27.4,9.69,5.82,2,27.4M275.08,2c-35.42,0-51.65,11.64-62.23,41.49S210.27,85,245.69,85s51.66-11.63,62.24-41.51S310.5,2,275.08,2m-2.95,41.49c-7.64,21.6-10.82,27.41-21.45,27.41s-9.69-5.81-2-27.41,10.83-27.4,21.45-27.4,9.69,5.82,2,27.4m124.25,16.9c10.34-29.19-49.06-21.48-43.48-37.25,1.27-3.58,5.85-7,13.31-7,6.71,0,8.49,3.92,6.83,8.61l-.67,1.91H404.8C410.86,9.49,400.65,2,370.45,2,341.19,2,323.7,10.83,318,26.94c-10,28.3,47.18,20.47,41.6,36.24-1.55,4.36-6.84,7.72-13.92,7.72-6.71,0-10.5-2.46-8.08-9.29l.67-1.9H304.71l-1,2.8C297.85,79.07,311.79,85,340.5,85c30.57,0,49.86-7.61,55.88-24.61M446.79,3.57,386.06,83.43H423l8.49-14h27.59l-1.41,14h36.91L490.4,3.57ZM460.5,53.79H441.12l21.5-33.89H463ZM588.65,3.57,571.22,52.78h-.37L565.91,3.57H527.13L498.84,83.43h32.44L548.75,34.1h.38l5.64,49.33h38L621.09,3.57Z"
              fill="#005eb8"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
