import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { LOCALSTORAGE_TOKEN } from "../constant";
import Swal from "sweetalert2";
import { apiLogout } from "../api/axios";
import { ICoreOutput } from "../api/type";

export const TeseterHeader = () => {
  const queryClient = useQueryClient();
  const naviage = useNavigate();

  const logoutMutation = useMutation(apiLogout, {
    onSuccess: (data: ICoreOutput) => {
      if (data.ok) {
        localStorage.removeItem(LOCALSTORAGE_TOKEN);
        queryClient.invalidateQueries("me");
        naviage(`/tester-login`);
        window.location.reload();
      }
    },
  });

  const logoutTester = () => {
    if (!logoutMutation.isLoading) {
      logoutMutation.mutate();
    }
  };

  // [마우스 오른쪽 클릭] / [컨트롤] / [F12] 금지
  useEffect(() => {
    const keyEvent = (e: any) => {
      if (e.ctrlKey) {
        e.preventDefault();
      }

      switch (e.key) {
        case "Control":
          Swal.fire({
            icon: "error",
            title: "Control 사용불가",
          });
          e.preventDefault();
          break;

        case "F12":
          e.preventDefault();
          break;

        case "Meta":
          Swal.fire({
            icon: "error",
            title: "윈도우키 사용불가",
          });
          e.preventDefault();
          break;

        default:
          break;
      }
    };
    document.addEventListener("keydown", keyEvent);
    return () => document.removeEventListener("keydown", keyEvent);
  }, []);

  return (
    <header className=" sticky top-0 left-0 right-0 z-10 bg-white  border-b-2 text-gray-900 py-3 min-w-max select-none">
      <div className="max-w-6xl mx-auto flex justify-between items-center ">
        <svg
          id="레이어_1"
          data-name="레이어 1"
          xmlns="http://www.w3.org/2000/svg"
          height="5mm"
          viewBox="0 0 621.09 85.33"
        >
          <title>doosan_ci</title>
          <path
            d="M79.62,3.57H29.29L1,83.43H51.34c40.07,0,48.86-19,56.27-39.94s12.09-39.92-28-39.92m-7.8,39.92C64.17,65.09,61.3,68.44,47.88,68.44H41L58.65,18.55h6.9c13.42,0,13.91,3.36,6.27,24.94M174.84,2c-35.43,0-51.66,11.64-62.24,41.49S110,85,145.44,85s51.66-11.63,62.24-41.51S210.26,2,174.84,2m-3,41.49c-7.64,21.6-10.82,27.41-21.45,27.41s-9.68-5.81-2-27.41,10.83-27.4,21.45-27.4,9.69,5.82,2,27.4M275.08,2c-35.42,0-51.65,11.64-62.23,41.49S210.27,85,245.69,85s51.66-11.63,62.24-41.51S310.5,2,275.08,2m-2.95,41.49c-7.64,21.6-10.82,27.41-21.45,27.41s-9.69-5.81-2-27.41,10.83-27.4,21.45-27.4,9.69,5.82,2,27.4m124.25,16.9c10.34-29.19-49.06-21.48-43.48-37.25,1.27-3.58,5.85-7,13.31-7,6.71,0,8.49,3.92,6.83,8.61l-.67,1.91H404.8C410.86,9.49,400.65,2,370.45,2,341.19,2,323.7,10.83,318,26.94c-10,28.3,47.18,20.47,41.6,36.24-1.55,4.36-6.84,7.72-13.92,7.72-6.71,0-10.5-2.46-8.08-9.29l.67-1.9H304.71l-1,2.8C297.85,79.07,311.79,85,340.5,85c30.57,0,49.86-7.61,55.88-24.61M446.79,3.57,386.06,83.43H423l8.49-14h27.59l-1.41,14h36.91L490.4,3.57ZM460.5,53.79H441.12l21.5-33.89H463ZM588.65,3.57,571.22,52.78h-.37L565.91,3.57H527.13L498.84,83.43h32.44L548.75,34.1h.38l5.64,49.33h38L621.09,3.57Z"
            fill="#005eb8"
          />
        </svg>
        <div className="flex-wrap">
          <div
            onClick={() => logoutTester()}
            className="text-gray-900 transform hover:text-red-500 cursor-pointer"
          >
            로그아웃 <FontAwesomeIcon className="fa-lg " icon={faSignOutAlt} />
          </div>
        </div>
      </div>
    </header>
  );
};
